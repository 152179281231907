import { createSelector } from 'reselect';

const studentReducer = (state) => state.studentReducer;

export const makeSelectStudentId = () => createSelector(
  studentReducer,
  (studentstate) => {
	return studentstate.studentId;
  }
);
export const makeSelectStudentSearchError = () => createSelector(
  studentReducer,
  (studentstate) => {
	return studentstate.studentSearchDataError;
  }
);
export const makeSelectStudentSearchData = () => createSelector(
  studentReducer,
  (studentstate) => {
	return studentstate.studentSearchData;
  }
);
export const makeSelectStudentSaveError = () => createSelector(
  studentReducer,
  (studentstate) => {
	return studentstate.studentSaveDataError;
  }
);
export const makeSelectStudentSaveData = () => createSelector(
  studentReducer,
  (studentstate) => {
	return studentstate.studentSaveData;
  }
);