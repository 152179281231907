export const LESSONS_FETCH_DATA = 'STUDENT_LESSONS_FETCH_DATA';
export const LESSONS_FETCH_DATA_SUCCESS = 'STUDENT_LESSONS_FETCH_DATA_SUCCESS';
export const LESSONS_FETCH_DATA_ERROR = 'STUDENT_LESSONS_FETCH_DATA_ERROR';
export const LESSONS_FETCH_STATUS_SUCCESS = 'STUDENT_LESSONS_FETCH_STATUS_SUCCESS';
export const LESSONS_FETCH_STATUS_ERROR = 'STUDENT_LESSONS_FETCH_STATUS_ERROR';
export const LESSONS_CANCEL = 'STUDENT_LESSONS_CANCEL';
export const LESSONS_CANCEL_SUCCESS = 'STUDENT_LESSONS_CANCEL_SUCCES';
export const LESSONS_CANCEL_ERROR = 'STUDENT_LESSONS_CANCEL_ERROR';
export const LESSON_DETAILS = 'STUDENT_LESSON_DETAILS';
export const LESSON_DETAILS_SUCCESS = 'STUDENT_LESSON_DETAILS_SUCCES';
export const LESSON_DETAILS_ERROR = 'STUDENT_LESSON_DETAILS_ERROR';
export const LESSON_SCHEDULE = 'LESSON_SCHEDULE';
export const LESSON_SCHEDULE_SUCCESS = 'LESSON_SCHEDULE_SUCCES';
export const LESSON_SCHEDULE_ERROR = 'LESSON_SCHEDULE_ERROR';
export const LESSON_SCHEDULE_UPDATE = 'LESSON_SCHEDULE_UPDATE';
export const LESSON_SCHEDULE_UPDATE_SUCCESS = 'LESSON_SCHEDULE_UPDATE_SUCCES';
export const LESSON_SCHEDULE_UPDATE_ERROR = 'LESSON_SCHEDULE_UPDATE_ERROR';
export const API_END_POINT = 'https://iae22t6gp5.execute-api.us-east-2.amazonaws.com';
