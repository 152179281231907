import React, { Component } from 'react';
import psdmg from '../../assets/images/detailed_page_no_image.PNG';
import { Link } from "react-router-dom";


class CourseDetails extends Component {
    constructor(props) {
      super(props);
    }

render() {
    const lessonIconUrl = this.props.courseDetails ? this.props.courseDetails.lessonIconUrl : '';
    const lessonIndex = this.props.courseDetails ? this.props.courseDetails.lessonIndex : '';
    const lessonTitle =  this.props.courseDetails ? this.props.courseDetails.lessonTitle : '';
    const lesssonDescription = this.props.courseDetails ? this.props.courseDetails.lesssonDescription : '';
    const teacherName = this.props.courseDetails ? this.props.courseDetails.teacherName : '';
    return(
        <div>
            <div class="bimger">
                   <div class="container-fluid">
                      <div class="row">
                       <div class="col-md-4 col-xl-2 col-12">
                           <h2 class="allessn"><Link to="/Courses" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><i class="fa fa-arrow-left" aria-hidden="true"></i> All Lessons</Link></h2> 
                           <img src={lessonIconUrl || psdmg} alt="My order" class="img-fluid"/>
                        </div>
                        <div class="col-md-6 col-xl-6 lsns">
                            <h2 class="allessn">Lesson {lessonIndex} </h2> 
                            <h1>{lessonTitle}</h1> 
                           <div class="row lcnschdl">
                             <div class="col-md-2 col-lg-2  col-xl-2 col-6">
                              <h2 class="allessn"> <i class="fa fa-graduation-cap" aria-hidden="true"></i>{teacherName}</h2> 
                             </div>
                          </div>
                       </div>
                       </div>
                    </div>
                </div>
                <div class="header-bg">
                   <div class="container">
                       <div class="row schdlsn">
                           <div class="col-md-12">
                                <h2>Course Contents</h2>
                                <div id="accordion">
                                      <div class="card">
                                        <div class="card-header" id="headingOne">
                                          <h5 class="mb-0">
                                            <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                             {lessonTitle}
                                            </button>
                                          </h5>
                                        </div>
                                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                          <div class="card-body">
                                            {lesssonDescription}
                                          </div>
                                        </div>
                                      </div>
                                    
                                </div>
                           </div>
                       </div>
                   </div> 
                </div>
        </div>
);
}
}
export default CourseDetails;