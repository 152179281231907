import { spawn } from 'redux-saga/effects';
import studentSaga from '../containers/Student/sagas';
import coursesSaga from '../containers/Courses/sagas';
import lessonsSaga from '../containers/Lessons/sagas';
import taskSaga from '../containers/Tasks/sagas';
import rateTeacherSaga from '../containers/RateTeacher/sagas';
import messagesSaga from '../containers/Messages/sagas';
import ordersSaga from '../containers/Orders/sagas';

export default function* rootSaga() {
    yield spawn(studentSaga);
    yield spawn(coursesSaga);
    yield spawn(lessonsSaga);
    yield spawn(taskSaga);
    yield spawn(rateTeacherSaga);
    yield spawn(messagesSaga);
    yield spawn(ordersSaga);
  }