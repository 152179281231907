import React, { Component } from 'react';
import { Link } from "react-router-dom";
import psdmg from '../../assets/images/detailed_page_no_image.PNG';
import Schedule from '../../containers/Schedule';
import CancelModal from '../CancelModal';
import RateTeacherModal from '../RateTeacher';

class LessonDetails extends Component {
    constructor(props) {
      super(props);
    }
    openLink(zoomLink){
      window.open(zoomLink,'_blank','fullscreen=1');
    }

render() {
    var displayTime = '';
    var buttonDisabled = false;
    const lessonIconUrl = this.props.lessonDetails ? this.props.lessonDetails.lessonIconUrl : '';
    const lessonIndex = this.props.lessonDetails ? this.props.lessonDetails.lessonIndex : '';
    const lessonTitle =  this.props.lessonDetails ? this.props.lessonDetails.lessonTitle : '';
    const teacherName = this.props.lessonDetails ? this.props.lessonDetails.teacherName : '';
    const startTime = this.props.lessonDetails ? this.props.lessonDetails.startTime : '';
    const zoomLink = this.props.lessonDetails ? this.props.lessonDetails.zoomLink : '';
    const attendanceStatus = this.props.lessonDetails ? this.props.lessonDetails.attendanceStatus : '';
    var scheduleText = startTime ? 'Cancel' : 'Schedule';
    var buttonClass = 'btn btn-theme-light btn-rounded wticnmb';
    const lessonDescription = this.props.lessonDetails ? this.props.lessonDetails.lessonDescription : '';
    if(startTime){
      const timeFormat = new Date(startTime);
      const defaultTime = new Date();
      if(defaultTime!==timeFormat) {
      displayTime=timeFormat.toLocaleString('en-US',{dateStyle: 'medium', timeStyle: 'short'});
      }
      var displayTimeDisable = timeFormat.getTime();
      var todaydate = new Date().getTime();
      var timeGap = (displayTimeDisable-todaydate)/(1000*3600);
      if(zoomLink && (timeGap <= 24) && (timeGap >= 0)){
          buttonDisabled = true;
          scheduleText = 'Go To Class';
          buttonClass = 'btn btn-theme-light btn-rounded actvbtn wticnmb';
      }
    }
   
    return(
        <div>
            <div class="bimger">
                   <div class="container-fluid">
                      <div class="row">
                       <div class="col-md-4 col-xl-2 col-12">
                           <h2 class="allessn"><Link to="/Lessons" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}> <i class="fa fa-arrow-left" aria-hidden="true"></i> All Classes </Link></h2> 
                           <img src={lessonIconUrl || psdmg} alt="My order" class="img-fluid"/>
                        </div>
                        <div class="col-md-6 col-xl-6 lsns">
                            <h2 class="allessn">Lesson {lessonIndex} </h2> 
                            <h1>{lessonTitle}</h1> 
                           <div class="row lcnschdl">
                             <div class="col-md-2 col-lg-2  col-xl-2 col-6">
                              <h2 class="allessn"> <i class="fa fa-graduation-cap" aria-hidden="true"></i>{teacherName}</h2> 
                             </div>
                             {displayTime?
                                 (<div class="col-md-3 col-lg-3 col-6">
                                 <h2 class="allessn"> <i class="fa fa-calendar-alt"></i><span class="bgylw">{displayTime}</span></h2> 
                                </div>)
                                :
                                ''
                             }
                            
                          </div>
                       </div>
                       <div class="col-md-2 col-12 cnlnbtn">
                          {attendanceStatus === 'attended' ?
                        <RateTeacherModal origin={1}/>
                        :((scheduleText==='Schedule') ?
                        <Schedule lessons={this.props.lessonDetails} refreshIndex={3}/>
                            :( (scheduleText==='Cancel') ?
                                <CancelModal lessons={this.props.lessonDetails} lessonsCancel={this.props.lessonsCancel} refreshIndex={3}/>
                                :
                                <button class={buttonClass} disabled={buttonDisabled} onClick={()=>this.openLink(zoomLink)}><i class="fas fa-calendar-alt"></i> {scheduleText}</button>
                            ))
                        }
                       </div>
                       </div>
                    </div>
                </div>
                <div class="header-bg">
                   <div class="container">
                       <div class="row schdlsn">
                           <div class="col-md-12">
                                <h2>Lesson Contents</h2>
                                <div id="accordion">
                                      <div class="card">
                                        <div class="card-header" id="headingOne">
                                          <h5 class="mb-0">
                                            <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                             {lessonTitle}
                                            </button>
                                          </h5>
                                        </div>
                                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                          <div class="card-body">
                                            {lessonDescription}
                                          </div>
                                        </div>
                                      </div>
                                    
                                </div>
                           </div>
                       </div>
                   </div> 
                </div>
        </div>
);
}
}
export default LessonDetails;