import { 
  RATE_TEACHER_FETCH_DATA,
  RATE_TEACHER_FETCH_DATA_SUCCESS,
  RATE_TEACHER_FETCH_DATA_ERROR,
} from './constants';

const initialState = {
  rateTeacherDataError: '',
  rateTeacherData: '',
};

export function rateTeacherReducer(state = initialState, action) {
  switch (action.type) {
	case  RATE_TEACHER_FETCH_DATA:
      return {
        ...state,
     };
	case  RATE_TEACHER_FETCH_DATA_SUCCESS:
      return {
        ...state,
        rateTeacherData: action.data
     };
	case  RATE_TEACHER_FETCH_DATA_ERROR:
      return {
        ...state,
        rateTeacherDataError: 'Some error occured, please contact our customer spport'
     };
    default:
      return state;
  }
}
