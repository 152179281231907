import React, { Component } from 'react';
import { Link } from "react-router-dom";
import photoshop from '../../assets/images/noimage.png';
import RateTeacherModal from '../RateTeacherModal';
import Schedule from '../../containers/Schedule';
import CancelModal from '../CancelModal';
import NoData from '../NoData';
import { isArray } from 'lodash';

const details = (studentId,selectedlessonId,lessonDetails) => {
    const payload={
        studentId: studentId,
        lessonId: selectedlessonId,
    };
    return lessonDetails(payload);
}


const LessonItems=(props) => {
    var displayTime = '';
    var buttonDisabled = false;
    const lessonTitle = props.lessons ? props.lessons.title : '';
    const lessonId = props.lessons ? props.lessons.lessonId : '';
    const lessonTeacher = props.lessons ? props.lessons.teacherName : '';
    const lessonDescription = props.lessons ? props.lessons.description : '';
    const lessonName = props.lessons ? props.lessons.lessonName : '';
    const classIcon = props.lessons ? props.lessons.classIcon : '';
    const startTime = props.lessons ? props.lessons.startTime : '';
    const zoomLink = props.lessons ? props.lessons.zoomlink : '';
    const classStatus = props.classStatus ? props.classStatus : 0;
    var scheduleText = startTime ? 'Cancel' : 'Schedule';
    const timeFormat = new Date(startTime);
    const defaultTime = new Date(null);
    var buttonClass = 'btn btn-theme-light btn-rounded wticnmb';
    if(defaultTime.getTime()!==timeFormat.getTime()) {
        displayTime=timeFormat.toLocaleString('en-US',{dateStyle: 'medium', timeStyle: 'short'})
    }
    var displayTimeDisable = timeFormat.getTime();
    var todaydate = new Date().getTime();
    var timeGap = (displayTimeDisable-todaydate)/(1000*3600);
    if(zoomLink && (timeGap <= 24) && (timeGap >= 0)){
        buttonDisabled = true;
        scheduleText = 'Go To Class';
        buttonClass = 'btn btn-theme-light btn-rounded actvbtn wticnmb';
    }
    return(
        <div className="col-md-6 col-lg-6 col-xl-3 col-sm-6 col-12">
            <div className="card card-theme mb-4">
                <div className="card-header flex-column">
                    <h4>{lessonName}</h4>
                    <div className="course d-flex align-items-center">
                        <img src={classIcon || photoshop} alt="photoshop" className="img-fluid mr-2 rounded-circle"/>
                        <p className="mb-0">{lessonTitle}</p>
                    </div>
                </div>
                <div className="card-body">
                    <div className="lesson-inner">
                        <div className="lesson-heading">{lessonDescription.substr(0,200)}...</div>
                    </div>
                    <div className="row icns">
                        <div className="col-md-6 col-lg-6 brdr">
                            <p><i className="fa fa-graduation-cap" aria-hidden="true"></i></p>
                            <p>{lessonTeacher}</p>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <p><i className="fa fa-calendar-alt"></i></p>
                            <p>{displayTime}</p>
                        </div>
                    </div>
                </div>
                <div className="card-foot">
                    <Link to="/LessonDetails" onClick={()=>details(1000,lessonId,props.lessonDetails)} style={{ cursor: 'default', color: 'inherit', textDecoration: 'inherit'}}><button className="btn btn-theme-light btn-rounded"><i className="fa fa-eye"></i> Details</button></Link>
                    {classStatus===3 ?
                         <RateTeacherModal origin={1}/>
                     :((scheduleText==='Schedule') ?
                     <Schedule lessons={props.lessons} refreshIndex={2}/>
                        :( (scheduleText==='Cancel') ?
                            <CancelModal lessons={props.lessons} lessonsCancel={props.lessonsCancel} refreshIndex={2}/>
                            :
                            <button class={buttonClass} disabled={buttonDisabled}><i class="fas fa-calendar-alt"></i> {scheduleText}</button>
                        ))
                    }
                   
                </div>
            </div>
        </div>
    );
}

class Lessons extends Component {
    constructor(props) {
      super(props);
      this.setActiveTabCompleted = this.setActiveTabCompleted.bind(this);
      this.setActiveTabUpcoming = this.setActiveTabUpcoming.bind(this);
      this.setActiveTabNotScheduled = this.setActiveTabNotScheduled.bind(this);
      this.state = {
        classUpcoming : 'nav-link active',
        classNotScheduled : 'nav-link',
        classCompleted : 'nav-link',
        tabStaus: 1,
      }
    }
    componentDidMount(){
        this.props.lessonsFetchData();
    }
    setActiveTabCompleted(e) {
        e.preventDefault();
        this.setState({
            classUpcoming: 'nav-link',
            classNotScheduled : 'nav-link',
            classCompleted: 'nav-link active',
            tabStaus: 3,
        }   
        );
    }
    setActiveTabNotScheduled(e) {
        e.preventDefault();
        this.setState({
            classNotScheduled : 'nav-link active',
            classUpcoming: 'nav-link',
            classCompleted: 'nav-link',
            tabStaus: 2,
        }   
        );
    }
    setActiveTabUpcoming(e) {
        e.preventDefault();
        this.setState({
            classUpcoming: 'nav-link active',
            classNotScheduled : 'nav-link',
            classCompleted: 'nav-link',
            tabStaus: 1
        });   
    }

render() {
    var lessonArray = [];
    const classStatus = this.state.tabStaus;
    const upcomingArray = this.props.lessonStatus ? this.props.lessonStatus.upcoming : [];
    const upcomingCount = isArray(upcomingArray) ? upcomingArray.length : 0;
    if( classStatus===1){
        lessonArray = this.props.lessonStatus ? this.props.lessonStatus.upcoming : [];
    } else if(classStatus===2){
        lessonArray = this.props.lessonStatus ? this.props.lessonStatus.notScheduled : [];
    }  else {
        lessonArray = this.props.lessonStatus ? this.props.lessonStatus.completed : [];
    }
    return(
        <div className="dash-content flex-grow-1">
           <div class="row">
            <div class="col-md-8 col-7">
                <h2 class="cus-heading mb-3">Class Schedule</h2>
            </div>
           
           </div>
            <section className="sliders mb-sm-4">
                <div className="row">
                    <div className="col-lg-12">
                        
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a className={this.state.classUpcoming} data-toggle="tab" href="#upcoming" onClick={this.setActiveTabUpcoming}>Upcoming &nbsp;&nbsp;<span className="badge badge-pill badge-warning">{upcomingCount}</span></a>
                            </li>
                            <li className="nav-item">
                                <a className={this.state.classNotScheduled} data-toggle="tab" href="#notscheduled" onClick={this.setActiveTabNotScheduled}>Not Scheduled</a>
                            </li>
                            <li className="nav-item">
                                <a className={this.state.classCompleted} data-toggle="tab" href="#completed" onClick={this.setActiveTabCompleted}>Completed</a>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane  active" id="upcoming">
                                <div className="card card-theme mb-4 nmecard">
                                    <div className="card-body">
                                        <div className="cuslessons">
                                            <div className="row">
                                            
                                                {lessonArray.length > 0 ?
                                                (lessonArray.map((lessonData, index) => (
                                                    <LessonItems index={index} lessons={lessonData} key={index} lessonsCancel={this.props.lessonsCancel} classStatus={classStatus} lessonDetails={this.props.lessonDetails}/>
                                                    )))
                                                    :
                                                    <NoData apiCalled={this.props.lessonStatus}/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
);
}
}
export default Lessons;