import React, { useState } from 'react';
import { Link } from "react-router-dom";
import student from '../../assets/images/noimage.png';
import logo from '../../assets/images/logo.png';
import calender from '../../assets/images/calander.png';
import mytask from '../../assets/images/my-task.png';
import message from '../../assets/images/message.png';
import courses from '../../assets/images/courses.png';
import cart from '../../assets/images/cart.png';

const SearchBar=(props) =>{
    var dashActive,lessonActive,taskActive,messageActive,courseAcive,ordersActive,profileActive,settingsActive,helpActive,logoutActive = 'menu-link';
    const [count, setCount] = useState(0);
    const firstName = props.studentData ? props.studentData.firstName: '';
    const lastName = props.studentData ? props.studentData.lastName: '';
    const studentImage = props.studentData ? props.studentData.profileImage: '';
    switch(count){
        case 1: {
            dashActive='menu-link';
            lessonActive='menu-link active';
            taskActive='menu-link';
            messageActive='menu-link';
            courseAcive='menu-link';
            ordersActive='menu-link';
            profileActive='menu-link';
            settingsActive='menu-link';
            helpActive='menu-link';
            logoutActive='menu-link';
            break;
        }
        case 2: {
            dashActive='menu-link';
            lessonActive='menu-link';
            taskActive='menu-link active';
            messageActive='menu-link';
            courseAcive='menu-link';
            ordersActive='menu-link';
            profileActive='menu-link';
            settingsActive='menu-link';
            helpActive='menu-link';
            logoutActive='menu-link';
            break;
        }
        case 3: {
            dashActive='menu-link';
            lessonActive='menu-link';
            taskActive='menu-link';
            messageActive='menu-link active';
            courseAcive='menu-link';
            ordersActive='menu-link';
            profileActive='menu-link';
            settingsActive='menu-link';
            helpActive='menu-link';
            logoutActive='menu-link';
            break;
        }
        case 4: {
            dashActive='menu-link';
            lessonActive='menu-link';
            taskActive='menu-link';
            messageActive='menu-link';
            courseAcive='menu-link active';
            ordersActive='menu-link';
            profileActive='menu-link';
            settingsActive='menu-link';
            helpActive='menu-link';
            logoutActive='menu-link';
            break;
        }
        case 5: {
            dashActive='menu-link';
            lessonActive='menu-link';
            taskActive='menu-link';
            messageActive='menu-link';
            courseAcive='menu-link';
            ordersActive='menu-link active';
            profileActive='menu-link';
            settingsActive='menu-link';
            helpActive='menu-link';
            logoutActive='menu-link';
            break;
        }
        default:{
            dashActive='menu-link active';
            lessonActive='menu-link';
            taskActive='menu-link';
            messageActive='menu-link';
            courseAcive='menu-link';
            ordersActive='menu-link';
            profileActive='menu-link';
            settingsActive='menu-link';
            helpActive='menu-link';
            logoutActive='menu-link';
            break;
        }
    }
    const dummyVoid = ()=>{
        return null;
    }

    // TODO student image need to be modified
    return(
        <>
            <header className="px-4 py-3 d-flex bg-white align-items-center dsktphead">
                        <div class="toggle-icon">
                            <a href="javascript:void(0);" class="btn btn-light togglebtn"><i class="fa fa-bars"></i></a>
                        </div>
                            <div className="d-flex align-items-center justify-content-end flex-grow-1 top-rightbar">
                                <div className="saerchbar px-4">
                                    <div className="dropdown mobile-search d-lg-none">
                                        <a href="#"><button className="btn btn-outline-secondary" type="button" id="search"><i className="fa fa-search"></i></button></a>
                                    </div>
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search..." aria-label="Search" aria-describedby="search"></input>
                                        <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" type="button" id="search"><i className="fa fa-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="notification pr-2">
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-secondary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-bell"></i> <span className="badge badge-pill badge-warning"></span></button>
                                    </div>
                                </div>
                                <div className="userprofile pl-3">
                                    <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="d-flex">
                                        <div className="userimg">
                                            <img src={studentImage || student}  alt="user image" className="img-fluid" />
                                        </div>
                                        <div className="user-name">
                                            <h5 className="mb-0">{firstName}</h5>
                                            <p className="mb-0">{lastName}</p>
                                        </div>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <Link to="/Profile" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className="dropdown-item">Profile</div></Link>
                                        <div className="dropdown-item">Logout</div>
                                    </div>
                                </div>
                            </div>
            </header>
            <div className="mobile-header">
                   <header className="px-4 py-3 d-flex bg-white align-items-center">
                      <div className="toggle-icon">
                         <a href="javascript:void(0);" className="btn btn-light togglebtn"><i className="fa fa-bars"></i></a>
                      </div>
                      <div className="d-flex align-items-center justify-content-end flex-grow-1 top-rightbar">
                            <a href="#" className="d-md-block"><img src={logo} alt="Jrcodebees" className="img-fluid lgimg" /></a>
                         <div className="notification pr-2">
                            <div className="btn-group">
                               <button type="button" className="btn btn-secondary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-bell"></i> <span className="badge badge-pill badge-warning"></span></button>
                            </div>
                         </div>
                         <div className="userprofile pl-3">
                            <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="d-flex">
                               <div className="userimg">
                                  <img src={student} alt="user image" className="img-fluid"/>
                               </div>
                               <div className="user-name">
                                  <h5 className="mb-0">{firstName}</h5>
                                  <p className="mb-0">{lastName}</p>
                               </div>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                                <Link to="/Profile" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className="dropdown-item">Profile</div></Link>
                               <div className="dropdown-item">Logout</div>
                            </div>
                         </div>
                      </div>
                    </header>
                    <div className="input-group">
                       <input type="text" className="form-control" placeholder="Search..." aria-label="Search" aria-describedby="search"/>
                       <div className="input-group-append">
                          <button className="btn btn-outline-secondary" type="button" id="search"><i className="fa fa-search"></i></button>
                       </div>
                    </div>
                    <div className="moblst">
                        <ul>
                            <li className="menu-item" onClick={() => setCount(1)}>
                                <Link to="/Lessons" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className={lessonActive}><img src={calender} alt="calander" className="img-fluid" /> <span>Schedule</span></div></Link>
                            </li>
                            <li className="menu-item" onClick={() => setCount(2)}>
                               <Link to="/Tasks" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className={taskActive}><img src={mytask} alt="my-task" className="img-fluid" /> <span>Tasks</span></div></Link>
                            </li>
                            <li className="menu-item" onClick={() => setCount(3)}>
                                <Link to="/Messages" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className={messageActive}><img src={message} alt="Messages" className="img-fluid" /> <span>Messages</span></div></Link>
                            </li>
                            <li className="menu-item" onClick={() => setCount(4)}>
                                <Link to="/Courses" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className={courseAcive}><img src={courses} alt="my-task" className="img-fluid" /> <span>Courses</span></div></Link>
                            </li>
                            <li className="menu-item" onClick={() => setCount(5)}>
                                <Link to="/Orders" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className={ordersActive}><img src={cart} alt="my-task" className="img-fluid" /> <span>Orders</span></div></Link>
                            </li>
                        </ul>
                    </div>
               </div>
               </>
    );
}
export default SearchBar;