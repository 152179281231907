import { all, call, put, takeLatest,select } from 'redux-saga/effects';
import { STUDENT_FETCH_DATA, API_END_POINT, STUDENT_SAVE_DATA, UPLOAD_PROFILE_PHOTO,STUDENT_SAVE_DATA_SUCCESS } from './constants';
import { makeSelectStudentId } from './selectors';
import {
  studentFetchDataSuccess,
  studentFetchDataError,
  studentSaveDataSuccess,
  studentSaveDataError,
 } from './actions';
import request, { headers } from '../../utils/request';

export function* fetchStudentData(payload) {
  const studentId = yield select(makeSelectStudentId());
  const DEFAULT_TIMEOUT = 20000;
  const requestURL = `${API_END_POINT}/student/${studentId}`;
  try{
    const response = yield call(request, requestURL, { timeout: DEFAULT_TIMEOUT, headers});
    var studentData = '';
    if(Array.isArray(response)) {
      studentData = response.filter((item)=> {return item.studentId === studentId });
    }
	  yield put(studentFetchDataSuccess(response[0]));
  } catch (error) {
    yield put(studentFetchDataError(error));
  }
  
}

export function* saveStudentData(payload) {
  const studentId = yield select(makeSelectStudentId());
  const data = payload.data || '';
  const DEFAULT_TIMEOUT = 20000;
  const requestURL = `${API_END_POINT}/student/${studentId}`;
  try{
    const response = yield call(request, requestURL, { method: 'PUT', data, timeout: DEFAULT_TIMEOUT, headers });
	  yield put(studentSaveDataSuccess(response));
  } catch (error) {
    yield put(studentSaveDataError(error));
  }
  
}

export function* photoUpload(payload) {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const studentId = yield select(makeSelectStudentId());
  const data = payload.data|| '';
  const requestURL = `${API_END_POINT}/student/${studentId}/upload`;
  const DEFAULT_TIMEOUT = 20000;
  const res = {};
  try{
    const response = yield call(request, requestURL, { method: 'POST', data, timeout: DEFAULT_TIMEOUT, headers });
    yield put(studentSaveDataSuccess(response));
  } catch (error) {
    yield put(studentSaveDataError(error));
  }
}

export function* fetchStudentDataDaemon() {
  yield takeLatest([STUDENT_FETCH_DATA,STUDENT_SAVE_DATA_SUCCESS], fetchStudentData);
}
export function* saveStudentDataDaemon() {
  yield takeLatest(STUDENT_SAVE_DATA, saveStudentData);
}
export function* photoUploadDaemon() {
  yield takeLatest(UPLOAD_PROFILE_PHOTO, photoUpload);
}

export default function* studentSaga() {
  yield all([fetchStudentDataDaemon(), saveStudentDataDaemon(), photoUploadDaemon()]);
}