import React, { Component } from 'react';
import user from '../../assets/images/user.png';
import { isArray } from 'lodash';


const MesgItems=(props) => {
    const teacherName = props.messages ? props.messages.teacherName : '';
    const teacherId = props.messages ? props.messages.teacherId : '';
    const teacherIcon = props.messages ? props.messages.teacherIcon : '';
    const submissionDueDate = props.messages ? props.messages.submissionDueDate : '';
    const timeFormat = new Date(submissionDueDate);
    const selectTeacher=(teacherId)=>{
       props.setTeacherId(teacherId);
    }
    return(
      <a href="javascript:void(0);" class="mbmsgpp">
      <div class="cus-message d-flex mb-2" onClick={()=>selectTeacher(teacherId)}>
         <div class="userimg mr-2">
            <img src={teacherIcon || user} alt="user" class="img-fluid"/>
         </div>
         <div class="message-detail">
            <div class="message-head d-flex align-items-center">
               <h6 class="name mb-0">{teacherName}</h6>
            </div>
         </div>
      </div>
    </a>
    );
}
const ChatMessages=(props)=>{
   const message= props.messagesData ? props.messagesData.message : '';
   const startTime = props.messagesData ? props.messagesData.time : '';
   const senderId = props.messagesData ? props.messagesData.senderUserId : '';
   const studentId = props.messagesData ? props.messagesData.studentId : '';
   const timeFormat = new Date(startTime);
   const displayTime=timeFormat.toLocaleString('en-US',{dateStyle: 'medium', timeStyle: 'short'});
   const alignClass = (senderId===studentId)? 'cus-message d-flex mb-2 usrmsg' : ' cus-message d-flex mb-2 replymsg';
   return(
      <div>
       {(senderId===studentId) ?
                                          (<div class="cus-message d-flex mb-2 usrmsg">
                                             <div class="userimg mr-2">
                                                <img src={user} alt="user" class="img-fluid"/>
                                             </div>
                                             <div class="message-detail">
                                                <div class="message-head d-flex align-items-center">
                                                   <div class="time">{displayTime}</div>
                                                </div>
                                                <div class="message">
                                                   <p>{message}</p>
                                                </div>
                                             </div>
                                          </div>)
                                          :
                                          (<div class="cus-message d-flex mb-2 replymsg">
                                             <div class="message-detail">
                                                <div class="message-head d-flex align-items-center">
                                                   <div class="time">{displayTime}</div>
                                                </div>
                                                <div class="message">
                                                   <p>{message}</p>
                                                </div>
                                             </div>
                                             <div class="userimg mr-2">
                                                <img src={user} alt="user" class="img-fluid"/>
                                             </div>
                                          </div>)
         }
      </div>
   );
}

class Messages extends Component {
    constructor(props) {
      super(props);
      this.setActiveTabCompleted = this.setActiveTabCompleted.bind(this);
      this.setActiveTabInProgress = this.setActiveTabInProgress.bind(this);
      this.setTeacherId = this.setTeacherId.bind(this);
      this.state = {
        classUpcoming : 'nav-link active',
        classCompleted : 'nav-link',
        tabStaus: 1,
        selctedTeacherId: 0,
        msg: '',
      }
    }
    componentDidMount() {
        this.props.messagesTeacher();
    }
    setTeacherId(teacherId){
       console.log('fg',teacherId);
      this.setState({
         selctedTeacherId: teacherId
      });
    }
    setActiveTabCompleted(e) {
        e.preventDefault();
        this.setState({
            classUpcoming: 'nav-link',
            classCompleted: 'nav-link active',
            tabStaus: 2,
        }   
        );
      }
    setActiveTabInProgress(e) {
        e.preventDefault();
        this.setState({
            classUpcoming: 'nav-link active',
            classCompleted: 'nav-link',
            tabStaus: 1
        });   
      }
    render() {
        const classStatus = this.state.tabStaus;
        var disableChat = false;
        var selectedTeacherId = 0;
        const messageData= this.props.messageData ? this.props.messageData : [];
        const studentData=this.props.studentData ? this.props.studentData : '';
        const nMessages= Array.isArray(messageData)? messageData.length : 0;
        var messagesTeachers = this.props.messagesTeacherData ? this.props.messagesTeacherData : [];
        if(this.state.selctedTeacherId===0){
         selectedTeacherId = (isArray(messageData) && messageData.length) > 0 ? messageData[0].teacherId : 0;
        }else{
         selectedTeacherId = this.state.selctedTeacherId;
        }
        if(selectedTeacherId===0){
         disableChat=true;
        }
        const currentMessageArrays = this.props.messageData ? this.props.messageData.filter((item)=>item.teacherId===selectedTeacherId):[];
        const messagesTeacher = messagesTeachers.slice(0,4);
        const currentMessageArraya = currentMessageArrays.slice(0,5);
        const currentMessageArray =currentMessageArrays.reverse();
        /*if( classStatus===1){
            tasksArray = this.props.tasksStatusData ? this.props.tasksStatusData.progress : [];
        } else {
            tasksArray = this.props.tasksStatusData ? this.props.tasksStatusData.completed : [];
        }*/
         const sMessage = this.props.sendMessages;
         const enterPressed=(event) =>{
            const payload={
               senderUserid: this.props.studentId,
               recipientUserid: selectedTeacherId,
               teacherId: selectedTeacherId,
               studentId: this.props.studentId,
               message: event.target.value,
            };
            var code = event.keyCode || event.which;
            if(code === 13) { //13 is the enter keycode
                //Do stuff in here
                sMessage(payload);
         }
        }
        
    return(
        <div className="dash-content flex-grow-1 msgpagetxt">
            <div class="row">
                <div class="col-md-8 col-7">
                    <h2 class="cus-heading mb-3">Messages</h2>
                </div>
            </div>
            <section class="sliders mb-sm-4">
                <div class="row">
                <div class="col-lg-12">
                <ul class="nav nav-tabs">
                              <li class="nav-item">
                                 <a class="nav-link active" data-toggle="tab" href="#recent">Recent Chats &nbsp;&nbsp;<span class="badge badge-pill badge-warning">{nMessages}</span></a>
                              </li>
                              <li class="nav-item">
                                 <a class="nav-link" data-toggle="tab" href="#archived">Archived</a>
                              </li>
                           </ul>
                <div class="tab-content">
                              <div class="tab-pane  active" id="recent">
                <div class="row">
                                    
                                    <div class="col-lg-4 col-md-5 hdmbf">
                                       <div class="card-massageinner">
                                       {
                                          messagesTeacher.length > 0 ?
                                             (messagesTeacher.map((messagesData, index) => (
                                                <MesgItems index={index} messages={messagesData} key={index} setTeacherId={this.setTeacherId}/>
                                             )))
                                             :
                                             ''
                                       }
                                       </div>
                                    </div>
                                    <div class="col-lg-8 col-md-7">
                                       <h2 class="cus-heading mb-3">{studentData.firstName}&nbsp;{studentData.lastName}</h2>
                                       <hr/>
                                       <div class="btm_message_text">
                                       {
                                          currentMessageArray.length > 0 ?
                                             (currentMessageArray.map((messagesData, index) => (
                                                <ChatMessages index={index} key={index} messagesData={messagesData}/>
                                             )))
                                             :
                                             ''
                                       }
                                          
                                          <div class="form-group">
                                             <textarea class="form-control" id="chatMessage" rows="3" onChange={e => this.setState({ msg: e.target.value })} disabled={disableChat} onKeyPress={enterPressed.bind(this)}></textarea>
                                          </div>
                                       </div>
                                    </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
        </div>
);
}
}
export default Messages;