import React, { useState} from 'react';
import StarRating from '../StarRating';
import photoshop from '../../assets/images/noimage.png';
import crossicn from '../../assets/images/crossicn.png';
import Popup from '../Popup';
import './modalstyle.css';

const RateTeacherModal=(props) =>{
   const [rating, setRating] = useState(0);
   const [showModal, toggleModal] = useState(false);
   const className = props.rates ? props.rates.className : '';
   const teacherName = props.rates ? props.rates.teacherName : '';
   const teacherProfilephoto = props.rates ? props.rates.teacherProfilephoto : '';
   const origin = props.origin ? props.origin : 0;
   const studentScheduleId = props.rates ? props.rates.studentScheduleId : '';
   const openModal=()=>{
      toggleModal(true);
    }
    function closeModal(boolSubmit){
      if(boolSubmit){
         console.log('submit',rating);
         const ratingData ={
            studentScheduleId: studentScheduleId,
            rating: rating,
            comments: document.getElementById('comments').value,
         }
         props.rateTeacher(ratingData);
         
      }
      toggleModal(false);
    }

   return (
     <span>
        { (origin===1) ?
        <button class="btn btn-theme-light btn-rounded wticnmb" onClick={openModal} data-toggle="modal" data-target="#feedbackmod"><i class="fa fa-star"></i> Feedback</button>
        :
        <button className="btn btn-block btn-theme-light" onClick={openModal} data-toggle="modal" data-target="#feedbackmod"><i className="fa fa-star"></i> Feedback & Rating</button>
        }
        
     { showModal ?
     (<Popup>
        <div class="modal fade bgmdl" id="feedbackmod" tabindex="-1" role="dialog" aria-labelledby="feedbackmod" aria-hidden="true">
         <div class="modal-dialog" role="document">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Rate Your Class</h5>
                  <button type="button" class="close" onClick={()=>closeModal(false)} data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><img src={crossicn}/></span>
                  </button>
               </div>
               <div class="modal-body">
                  <img src={teacherProfilephoto} alt="userimage" class="img-fluid mr-2 rounded-circle usrimg"/>
                  <p><b>{teacherName}</b></p>
                  <div class="course d-flex align-items-center">
                     <img src={photoshop} alt="photoshop" class="img-fluid mr-2 rounded-circle fdmgpp"/>
                     <p class="mb-0">{className}</p>
                  </div>
               </div>
               <div class="modal-footer">
                  <div class="form-group">
                     <label for="exampleFormControlTextarea1">Provide Feedback: 
                       <StarRating numberOfStars="5" currentRating="0" onClick={setRating}/>
                     </label>
                     <textarea class="form-control" id="comments" rows="3">Your Feedback</textarea>
                  </div>
                  <button class="btn btn-theme-light btn-rounded sbmitfoot" data-dismiss="modal" onClick={()=>closeModal(true)}><i class="fa fa-arrow-up" aria-hidden="true"></i>Submit</button>
               </div>
            </div>
         </div>
         </div>
      </Popup>)
      :
      ''
}
      </span> 
    );
}
export default RateTeacherModal;