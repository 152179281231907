import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import RateTeacher from '../../components/RateTeacherModal';
import { rateTeacher } from './actions';
import { makeSelectRateTeacherError } from './selectors'

const mapStateToProps = createStructuredSelector({
  rateTeacherError: makeSelectRateTeacherError(),
 });

export function mapDispatchToProps(dispatch) {
  return {
    rateTeacher: (data) => dispatch(rateTeacher(data)),
    dispatch,
  };
}

const RateTeacherContainer = connect(mapStateToProps,mapDispatchToProps)(RateTeacher);

export default RateTeacherContainer;