import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { studentReducer } from '../containers/Student/reducer';
import { coursesStudentReducer } from '../containers/Courses/reducer';
import { lessonsStudentReducer } from '../containers/Lessons/reducer';
import { tasksStudentReducer } from  '../containers/Tasks/reducer';
import { rateTeacherReducer } from  '../containers/RateTeacher/reducer';
import { messagesReducer } from  '../containers/Messages/reducer';
import { ordersReducer } from '../containers/Orders/reducer';

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    studentReducer,
    coursesStudentReducer,
    lessonsStudentReducer,
    tasksStudentReducer,
    rateTeacherReducer,
    messagesReducer,
    ordersReducer,
  });
 
  export default createRootReducer;
