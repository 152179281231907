import React, { Component } from 'react';
import freeProfile from '../../assets/images/noimage.png';
import { Link } from "react-router-dom";

class Profile extends Component {
    constructor(props) {
      super(props);
      this.state = {
        tabStaus: 1,
      }
    }
    
    render() {
      var displayTime = '';
      const studentData = this.props.studentData ? this.props.studentData : '';
      const dob = this.props.studentData ?this.props.studentData.dob : '';
      const timeFormat = new Date(dob);
      if(dob!=='0000-00-00'){
         displayTime=timeFormat.toLocaleString('en-US',{dateStyle: 'medium'})
      }
    return(
      <div class="dash-content flex-grow-1">
         <div class="row">
            <div class="col-md-8 col-7">
               <h2 class="cus-heading mb-3">My Profile</h2>
            </div>
         </div>
         <div class="dash-content flex-grow-1">
                     <section class="sliders mb-sm-4">
                        <div class="row">
                           <div class="col-lg-2 col-md-12">
                              <div class="profile-information">
                                 <div class="profile-img">
                                    <img src={studentData.profileImage || freeProfile} alt="photoshop" class="img-fluid mr-2 rounded-circle"/>
                                 </div>
                                 <h3>{studentData.firstName}</h3>
                                 <p>Roll No: {studentData.studentId}</p>
                                 <div class="brdrbtm"></div>
                                 <div class="prfletext">
                                    <h3></h3>
                                    <p></p>
                                 </div>
                                 <div class="brdrbtm"></div>
                                 <div class="prfletext">
                                    <h3></h3>
                                    <p></p>
                                 </div>
                              </div>
                           </div>
                           <div class="col-lg-10 col-md-12">
                              <div class="card card-theme mb-4 prfle">
                                 <div class="card-header">
                                    <h4>Personal Details</h4>
                                       <Link to="/EditProfile" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div class="viewall"><i class="fas fa-pencil-alt"></i></div></Link>
                                 </div>
                                 <div class="card-body p-1">
                                    <div class="cus-table table-responsive">
                                       <table class="table prfletable">
                                          <tbody>
                                             <tr>
                                                <td>
                                                   <span>Student Name</span>
                                                </td>
                                                <td>
                                                   <span>Parent Name</span>
                                                </td>
                                                <td>
                                                   <span>Gender</span>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td><b>{studentData.firstName}&nbsp;{studentData.lastName}</b></td>
                                                <td><b>{studentData.parentName}</b></td>
                                                <td><b>{studentData.gender}</b></td>
                                             </tr>
                                             <tr class="spcng"></tr>
                                             <tr>
                                                <td>
                                                   <span>Time Zone</span>
                                                </td>
                                                <td>
                                                   <span>School/College</span>
                                                </td>
                                                <td>
                                                   <span>Grade</span>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td>
                                                   <b>{studentData.timezone}</b>
                                                </td>
                                                <td>
                                                   <b>{studentData.school}</b>
                                                </td>
                                                <td>
                                                   <b>{studentData.grade}</b>
                                                </td>
                                             </tr>
                                             <tr class="spcng"></tr>
                                             <tr>
                                                <td>
                                                   <span>Date of Birth</span>
                                                </td>
                                                <td>
                                                   <span>Login Email</span>
                                                </td>
                                                <td>
                                                   <span>Password</span>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td>
                                                   <b>{displayTime}</b>
                                                </td>
                                                <td>
                                                   <b>{studentData.email}</b>
                                                </td>
                                                <td>
                                                   <b></b>
                                                </td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                              </div>
                              <div class="card card-theme mb-4 prfle">
                                 <div class="card-header">
                                    <h4>Contact Details</h4>
                                    <Link to="/EditProfile" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div class="viewall"><i class="fas fa-pencil-alt"></i></div></Link>
                                 </div>
                                 <div class="card-body p-1">
                                    <div class="cus-table table-responsive">
                                       <table class="table prfletable">
                                          <tbody>
                                             <tr>
                                                <td>
                                                   <span>Address</span>
                                                </td>
                                                <td>
                                                   <span>Primary Contact Number</span>
                                                </td>
                                                <td>
                                                   <span>Secondary Contact Number</span>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td><b>{studentData.address || ''}<br/>{studentData.address_line2 || ''}<br/>{studentData.city || ''} {studentData.state || ''} {studentData.zip || ''}<br/>{studentData.country}</b></td>
                                                <td><b>{studentData.primaryContact}</b></td>
                                                <td><b>{studentData.secContact}</b></td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                              </div>
                              <div class="card card-theme mb-4 prfle">
                                 <div class="card-header">
                                    <h4>Settings</h4>
                                    <Link to="/EditProfile" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div class="viewall"><i class="fas fa-pencil-alt"></i></div></Link>
                                 </div>
                                 <div class="card-body p-1">
                                    <div class="cus-table table-responsive">
                                       <table class="table prfletable">
                                          <tbody>
                                             <tr>
                                                <td>
                                                   <span><b>Password:</b></span>
                                                </td>
                                                <td>
                                                   <span>******</span>
                                                </td>
                                                <td>
                                                   <span></span>
                                                </td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </section>
                  </div>
      </div>
   );
}
}
export default Profile;