import {
  RATE_TEACHER_FETCH_DATA,
  RATE_TEACHER_FETCH_DATA_SUCCESS,
  RATE_TEACHER_FETCH_DATA_ERROR,
  RATE_TEACHER,
  RATE_TEACHER_SUCCESS,
  RATE_TEACHER_ERROR,
} from './constants';

export function rateTeacherFetchData(data) {
  return {
    type:  RATE_TEACHER_FETCH_DATA,
    data,
  };
}
export function rateTeacherFetchDataSuccess(data) {
  return {
    type:  RATE_TEACHER_FETCH_DATA_SUCCESS,
	data,
  };
}
export function rateTeacherFetchDataError(error) {
  return {
    type:  RATE_TEACHER_FETCH_DATA_ERROR,
	error,
  };
}
export function rateTeacher(data) {
  return {
    type:  RATE_TEACHER,
    data,
  };
}
export function rateTeacherSuccess(data) {
  return {
    type:  RATE_TEACHER_SUCCESS,
	data,
  };
}
export function rateTeacherError(error) {
  return {
    type:  RATE_TEACHER_ERROR,
	error,
  };
}
