import { all, call, put, takeLatest,select } from 'redux-saga/effects';
import { STUDENT_FETCH_DATA, API_END_POINT } from '../Student/constants';
import { makeSelectStudentId } from '../Student/selectors';
import { RATE_TEACHER, RATE_TEACHER_SUCCESS } from './constants';
import { rateTeacherFetchDataSuccess,
  rateTeacherFetchDataError,
  rateTeacherSuccess,
  rateTeacherError
} from './actions';
import request, { headers } from '../../utils/request';

export function* rateTeacherData(payload) {
  const studentId = yield select(makeSelectStudentId());
  const DEFAULT_TIMEOUT = 20000;
  const requestURL = `${API_END_POINT}/student/${studentId}/rating`;
  try{
    const response = yield call(request, requestURL, { timeout: DEFAULT_TIMEOUT, headers });
	  yield put(rateTeacherFetchDataSuccess(response));
  } catch (error) {
    yield put(rateTeacherFetchDataError(error));
  }
}

export function* rateTeacher(payload) {
  const studentId = yield select(makeSelectStudentId());
  const data = payload.data || '';
  const DEFAULT_TIMEOUT = 20000;
  const requestURL = `${API_END_POINT}/student/${studentId}/rating`;
  try{
    const response = yield call(request, requestURL, { method: 'POST', data, timeout: DEFAULT_TIMEOUT, headers });
	  yield put(rateTeacherSuccess(response));
  } catch (error) {
    yield put(rateTeacherError(error));
  }
  
}

export function* rateTeacherDataDaemon() {
  yield takeLatest([STUDENT_FETCH_DATA, RATE_TEACHER_SUCCESS], rateTeacherData);
}
export function* rateTeacherDaemon() {
  yield takeLatest(RATE_TEACHER, rateTeacher);
}

export default function* rateTeacherSaga() {
  yield all([rateTeacherDataDaemon(),rateTeacherDaemon()]);
}