import { 
  COURSES_FETCH_DATA,
  COURSES_FETCH_DATA_SUCCESS,
  COURSES_FETCH_DATA_ERROR,
  ADV_FETCH_DATA_SUCCESS,
  ADV_FETCH_DATA_ERROR,
  COURSES_FETCH_STATUS_SUCCESS,
  COURSES_FETCH_STATUS_ERROR,
  COURSES_DETAILS,
  COURSES_DETAILS_SUCCESS,
  COURSES_DETAILS_ERROR,
} from './constants';

const initialState = {
  coursesDataError: '',
  coursesData: '',
  advDataError: '',
  advData: '',
  coursesStatusError: '',
  coursesStatus: '',
  coursesDetailsError: '',
  coursesDetails: '',
};

export function coursesStudentReducer(state = initialState, action) {
  switch (action.type) {
	  case  COURSES_FETCH_DATA:
      return {
        ...state,
     };
	  case  COURSES_FETCH_DATA_SUCCESS:
      return {
        ...state,
        coursesData: action.data
     };
	  case  COURSES_FETCH_DATA_ERROR:
      return {
        ...state,
        coursesDataError: 'Some error occured, please contact our customer spport'
     };
    case  ADV_FETCH_DATA_SUCCESS:
      return {
        ...state,
        advData: action.data
      };
    case  ADV_FETCH_DATA_ERROR:
      return {
          ...state,
          advDataError: 'Some error occured, please contact our customer support'
      };
    case  COURSES_FETCH_STATUS_SUCCESS:
      return {
          ...state,
          coursesStatus: action.data
      };
    case  COURSES_FETCH_STATUS_ERROR:
      return {
            ...state,
            coursesStatusError: 'Some error occured, please contact our customer support'
        };
    case  COURSES_DETAILS:
      return {
            ...state,
         };
    case  COURSES_DETAILS_SUCCESS:
      return {
            ...state,
            coursesDetails: action.data
         };
    case  COURSES_DETAILS_ERROR:
      return {
            ...state,
            coursesDetailsError: 'Some error occured, please contact our customer spport'
         };
    default:
      return state;
  }
}
