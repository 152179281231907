import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import EditProfile from '../../components/EditProfile';
import { studentSaveData,uploadPhoto } from '../Student/actions';
import { makeSelectStudentSaveData } from '../Student/selectors'

const mapStateToProps = createStructuredSelector({
  updatedData: makeSelectStudentSaveData(),
});

export function mapDispatchToProps(dispatch) {
  return {
    updateStudent: (data) => dispatch(studentSaveData(data)),
    uploadPhoto: (data) => dispatch(uploadPhoto(data)),
    dispatch,
  };
}

const EditProfileContainer = connect(mapStateToProps,mapDispatchToProps)(EditProfile);

export default EditProfileContainer;