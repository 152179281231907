import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import TaskDetails from '../../components/TaskDetails';
import { tasksDetails, tasksFileUpload,tasksDataUpload } from  '../Tasks/actions';
import { makeSelectTaskDetails } from '../Tasks/selectors';

const mapStateToProps = createStructuredSelector({
  taskDetailsData: makeSelectTaskDetails(),
});

export function mapDispatchToProps(dispatch) {
  return {
    tasksFileUpload: (data) => dispatch(tasksFileUpload(data)),
    tasksDetails: (data) => dispatch(tasksDetails(data)),
    tasksDataUpload: (data) => dispatch(tasksDataUpload(data)),
    dispatch,
  };
}

const TaskDetailsContainer = connect(mapStateToProps,mapDispatchToProps)(TaskDetails);

export default TaskDetailsContainer;