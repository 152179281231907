import { 
  LESSONS_FETCH_DATA,
  LESSONS_FETCH_DATA_SUCCESS,
  LESSONS_FETCH_DATA_ERROR,
  LESSONS_FETCH_STATUS_SUCCESS,
  LESSONS_FETCH_STATUS_ERROR,
  LESSON_DETAILS,
  LESSON_DETAILS_SUCCESS,
  LESSON_DETAILS_ERROR,
  LESSON_SCHEDULE,
  LESSON_SCHEDULE_SUCCESS,
  LESSON_SCHEDULE_ERROR,
} from './constants';

const initialState = {
  lessonsDataError: '',
  lessonsSearchData: '',
  lessonsStatusError: '',
  lessonsStatusData: '',
  lessonDetails: '',
  lessonDetailsError: '',
  lessonScheduleData: '',
  lessonScheduleError: '',
  lessonDetailsFetch: '',
};

export function lessonsStudentReducer(state = initialState, action) {
  switch (action.type) {
	case  LESSONS_FETCH_DATA:
      return {
        ...state,
     };
	case  LESSONS_FETCH_DATA_SUCCESS:
    //console.log('red',action.data);
      return {
        ...state,
        lessonsSearchData: action.data
     };
	case  LESSONS_FETCH_DATA_ERROR:
      return {
        ...state,
        lessonsDataError: 'Some error occured, please contact our customer spport'
     };
  case  LESSONS_FETCH_STATUS_SUCCESS:
      return {
        ...state,
        lessonsStatusData: action.data
      };
  case  LESSONS_FETCH_STATUS_ERROR:
      return {
        ...state,
        lessonsStatusError: 'Some error occured, please contact our customer spport'
      };
  case  LESSON_DETAILS:
      return {
        ...state,
        lessonDetailsFetch: action.data
       };
  case  LESSON_DETAILS_SUCCESS:
      return {
        ...state,
        lessonDetails: action.data
       };
  case  LESSON_DETAILS_ERROR:
      return {
        ...state,
          lessonDetailsError: 'Some error occured, please contact our customer spport'
       };
  case  LESSON_SCHEDULE:
      return {
        ...state,
      };
  case  LESSON_SCHEDULE_SUCCESS:
      return {
        ...state,
        lessonScheduleData: action.data
      };
  case  LESSON_SCHEDULE_ERROR:
      return {
        ...state,
        lessonScheduleError: 'Some error occured, please contact our customer spport'
      };
  default:
      return state;
  }
}
