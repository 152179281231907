import { 
  STUDENT_FETCH_DATA,
  STUDENT_FETCH_DATA_SUCCESS,
  STUDENT_FETCH_DATA_ERROR,
  STUDENT_SAVE_DATA,
  STUDENT_SAVE_DATA_SUCCESS,
  STUDENT_SAVE_DATA_ERROR,
} from './constants';

const initialState = {
  studentSearchDataError: '',
  studentSearchData: '',
  studentSaveDataError: '',
  studentSaveData: '',
  studentId: '',
};

export function studentReducer(state = initialState, action) {
  switch (action.type) {
	case  STUDENT_FETCH_DATA:
      return {
        ...state,
        studentId: action.data.studentId
     };
	case  STUDENT_FETCH_DATA_SUCCESS:
      return {
        ...state,
        studentSearchData: action.data
     };
	case  STUDENT_FETCH_DATA_ERROR:
      return {
        ...state,
        studentSearchDataError: 'Some error occured, please contact our customer spport'
     };
  case  STUDENT_SAVE_DATA:
      return {
        ...state,
     };
	case  STUDENT_SAVE_DATA_SUCCESS:
      return {
        ...state,
        studentSearchData: action.data
     };
	case  STUDENT_SAVE_DATA_ERROR:
      return {
        ...state,
        studentSearchDataError: 'Some error occured, please contact our customer spport'
     };
    default:
      return state;
  }
}
