import React from 'react';
import LeftPane from '../Leftpane';
import RightPane from '../Rightpane';
import './styles.css';

class Layout extends React.Component {
    constructor(props) {
      super(props);
    }
    componentDidMount() {
        const search = window.location.search;
        const params = search.split('?');
        const payload={
            studentId:params[1] || '',
        };
        this.props.studentFetchData(payload);
    }
    render() {
		return (
			<main className="d-flex min-vh-100 flex-column">
				<div className="dashboard d-flex min-vh-100">
                    <div className="left-sidebar">
                        <LeftPane {...this.props}/>
                    </div>
                    <div className="right-sidebar d-flex flex-column">
                        <RightPane {...this.props}/>
                    </div>
                </div>
			</main>
		);
    }
}

export default Layout;