import React from 'react';
import photoshop from '../../assets/images/noimage.png';
import RateTeacherModal from '../../containers/RateTeacher';
import NoData from '../NoData';

 
const RateItems=(index, rate) => {
    const className = index.rate ? index.rate.className : '';
    const teacherName = index.rate ? index.rate.teacherName : '';
    const teacherProfilephoto = index.rate ? index.rate.teacherProfilephoto : '';
    return(
        <div className="col-md-6 col-lg-6 col-xl-4">
            <div className="rate-teacherbox">
                <img src={teacherProfilephoto} alt="maria" className="img-fluid rounded-circle teacherimg" />
                <h4>{teacherName}</h4>
                <div className="subject d-flex justify-content-center align-items-center">
                    <img src={photoshop} alt="Photoshop" className="img-fluid rounded-circle mr-2" />
                    <p className="mb-0">{className}</p>
                </div>
                <RateTeacherModal rates={index.rate}/>
            </div>
        </div>
    );
}

const RateTeacher=(props) =>{
    var rateTeacherArray = props.rateTeacherData ? props.rateTeacherData : [];
    if(Array.isArray(rateTeacherArray)) {
        const sliceLength = (rateTeacherArray.length > 2) ? 3 : rateTeacherArray.length;
        rateTeacherArray = rateTeacherArray.slice(0, sliceLength);
    }
    return(
        <div className="card card-theme">
            <div className="card-header">
                <h4>Rate your Class</h4>
            </div>
            <div className="card-body">
                <div className="rate-teacher">
                    <div className="row">
                        {rateTeacherArray.length > 0 ?
                        (rateTeacherArray.map((teacherData, index) => (
                            <RateItems index={index} rate={teacherData} key={index} />
                            )))
                            :
                            <NoData apiCalled={props.rateTeacherData}/>
                        }
                    </div>
                </div>
            </div>
        </div>
);
}
export default RateTeacher;