import React, { Component, useState, useEffect } from 'react';
import moment from 'moment';
import ai from '../../assets/images/noimage.png';
import crossicn from '../../assets/images/crossicn.png';
import Popup from '../Popup';

const InnerRow=(props)=>{
   var buttonDisabled = false;
   const {selCols, selRows, item,rowItem,indexItem,handleClick} = props;
   const scTeacherScheduleId = item.teacherScheduleId || 0;
   const scStartTime = item.scheduleStartdate || '';
   if((moment().format()) <= (moment(scStartTime).format())){
      buttonDisabled=true;
   }
   const sheduleData = {
      startTime: scStartTime,
      teacherScheduleId: scTeacherScheduleId,
   }
   const temp = ((selRows==rowItem)) ? 1 : 2;
   const innerHandleClick=(indexItem,rowItem)=>{
      handleClick(indexItem,rowItem,sheduleData);
}
return(
   
            <div className={(selRows===rowItem && selCols===indexItem) ? 'calbox1 d-flex align-items-center actvee' : 'calbox1 d-flex align-items-center'} id="divItem" disabled={buttonDisabled} onClick={()=>innerHandleClick(indexItem,rowItem)}>
               <p><i class={(selRows===rowItem && selCols===indexItem) ? 'fas fa-check' : ''}></i>{new Date(item.scheduleStartdate).toLocaleTimeString('en-US',{hour: '2-digit', minute:'2-digit'})}</p>
            </div>
         
)
}

const TimeItems=(props)=>{
   console.log('pro',props);
   const filteredArray= props.selectedWeek? props.selectedWeek.filter((item)=>moment(item.scheduleStartdate).format("D M YYYY")===props.weekData.format("D M YYYY")) : '';
   const rowItem = props.indexItem;
   const selRows = props.selRow;
   const selCols = props.selCol;
   const handleClick=(indexItem,rowItem,sheduleData)=>{
      props.tableHandleClick(indexItem,rowItem,sheduleData);
   }
   return(
     
          <div class="col-md-10" >
         <div class="calender-boxes">
      { (filteredArray && filteredArray.length > 0) ?
         (filteredArray.map((item,indexItem)=>(
            <InnerRow indexItem={indexItem} rowItem={rowItem} selRows={selRows} selCols={selCols} item={item} handleClick={handleClick}/>
         )))
      :
      <div class="col-md-10 actvetm">
            <span><i class="fas fa-exclamation-triangle"></i>&nbsp;&nbsp;No slots available</span>
      </div>
      }
      </div>
      </div>
   );
}

const ScheduleItems=(props) => {
   const [columnIndex,setColumnIndex] = useState(1000);
   const [rowIndex,setRowIndex] = useState(1001);
   const selectedWeek = props.selectedWeek;
   var weekStart = moment().add(props.index,'weeks').startOf('isoWeek');
   //console.log('weekStart',weekStart.format("d MMM"));
   var week1 = [];
   for (var i = 0; i <= 6; i++) {
      week1.push(moment(weekStart).add(i, 'days'));
   }
   const tableHandleClick=(indexItem,row,sheduleData)=>{
      setColumnIndex(indexItem);
      setRowIndex(row);
      props.setScheduleId(sheduleData);
   }
   useEffect(() => {
      setColumnIndex(1000);
      setRowIndex(1000);; //children function of interest
    }, [selectedWeek]);
   return(
      <div>
      {
         week1.map((weekData, index) => (
            <div class="row">
               <div class="col-md-2">
                  <p class="hdtxt"><b>{weekData.format("D MMM")}</b></p>
                  <p class="hdtxt"><b>{weekData.format("ddd")}</b></p>
               </div>
               <TimeItems indexItem={index} weekData={weekData} selectedWeek={selectedWeek} tableHandleClick={tableHandleClick} selRow={rowIndex} selCol={columnIndex}/>
            </div>
          ))
      }
      </div>
   );
}
class Schedule extends Component {
    constructor(props) {
      super(props);
      this.openModal = this.openModal.bind(this);
      this.closeModal = this.closeModal.bind(this);
      this.selectWeek = this.selectWeek.bind(this);
      this.setScheduleId = this.setScheduleId.bind(this);
      this.state = {
         modalIsOpen : false,
         selectedWeekIndex : 0,
         dateArray: '',
         scheduleId: {},
         scheduleSelected: false,
      }
    }
    componentDidMount(){
      
    }
    openModal(){
      this.setState({modalIsOpen:true});
      const payload={
         teacherId:this.props.lessons.teacherId,
     };
     this.props.lessonsSchedule(payload);
    }
    closeModal(boolSubmit){
      if(boolSubmit && this.state.scheduleSelected){
         const refreshIndex = this.props.refreshIndex ? this.props.refreshIndex : '';
         const scheduleData={
            classId: this.props.lessons ? this.props.lessons.classId : '',
            startTime: this.state.scheduleId.startTime || '',
            zoomLink: this.props.lessons ? this.props.lessons.zoomLink : '',
            meetingId: this.props.lessons ? this.props.lessons.meetingId : '',
            teacherScheduleId:  this.state.scheduleId.teacherScheduleId || '',
         };
         const payload= { refreshIndex, scheduleData}
         this.props.scheduleUpdate(payload);
      }
      this.setState({modalIsOpen:false});
    }
    selectWeek(index,scheduleArray){
      this.setState({selectedWeekIndex:index});
      const dArray = {
         0 : scheduleArray.Week1 || [],
         1 : scheduleArray.Week2 || [],
         2 : scheduleArray.Week3 || [],
         3 : scheduleArray.Week4 || [],
      };
      this.setState({dateArray:dArray[index]});
    }
    setScheduleId(schId){
      this.setState({scheduleId:schId,scheduleSelected:true});
    }

render() {
   var selectedWeekIndex=this.state.selectedWeekIndex;
   var selectedWeek = '';
   const lessonId = this.props.lessons ? this.props.lessons.lessonIndex : '';
   const zoomLink = this.props.lessons ? this.props.lessons.zoomLink : '';
   const calssId = this.props.lessons ? this.props.lessons.calssId : '';
   const teacherName = this.props.lessons ? this.props.lessons.teacherName : '';
   const lessonName = this.props.lessons ? this.props.lessons.lessonName : '';
   const scheduleArray = this.props.scheduleDetails ? this.props.scheduleDetails : '';
   const weekArray = (scheduleArray.length > 0) ? scheduleArray.map(week => week.Week): [];
   let uniqueWeek = [1,2,3,4];
   if(!this.state.dateArray){
      selectedWeek = scheduleArray.Week1;
   }else{
      selectedWeek=this.state.dateArray;
   }
   return(
       <span>
      <button class="btn btn-theme-light btn-rounded wticnmb" data-toggle="modal" data-target="#schdule" onClick={this.openModal}><i class="fa fa-calendar-alt"></i> Schedule</button>
      { this.state.modalIsOpen ?
      (<Popup>
      <div class="modal fade bgmdl" id="schdule" tabindex="-1" role="dialog" aria-labelledby="schdule" aria-hidden="true">
      <div class="modal-dialog" role="document">
         <div class="modal-content">
            <div class="modal-header">
               <h5 class="modal-title" id="exampleModalLabel"><i class="fa fa-calendar-alt"></i>&nbsp;&nbsp;Schedule Lesson</h5>
               <button type="button" onClick={()=>this.closeModal(false)} class="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true"><img src={crossicn}/></span>
               </button>
            </div>
            <div class="modal-body">
               <div class="bgimghd">
                  <div class="course d-flex align-items-center mdlbg">
                     <img src={ai} alt="userimage" class="img-fluid mr-2 rounded-circle"/>
                     <p class="mb-0">{lessonName}</p>
                  </div>
                  <p class="etcp"> <b>Lesson {lessonId}</b><span><i class="fa fa-graduation-cap" aria-hidden="true"></i> &nbsp;{teacherName}</span></p>
               </div>
               <h4>Select the slots suitable for you. We recommend 3 weekly classes for deeper learning</h4>
               <div class="row hdrw">
                  {
                        uniqueWeek.length > 0 ?
                            (uniqueWeek.map((weekData, index) => (
                              <div class={(this.state.selectedWeekIndex===index) ? 'col-md-3 hdcol active':'col-md-3 hdcol'} id="weeksId" onClick={()=>this.selectWeek(index,scheduleArray)}>
                              <p class="hdtxt"><b>Week {weekData}</b></p>
                           </div>
                            )))
                            :
                            ''
                        }
               </div>
              
                 
                     <ScheduleItems index={selectedWeekIndex} selectedWeek={selectedWeek} setScheduleId={this.setScheduleId}/>
                  
              
               <button class="btn btn-theme-light btn-rounded schedulebtn" data-dismiss="modal" onClick={()=>this.closeModal(true)}>Schedule</button>
            </div>
         </div>
      </div>
   </div>
   </Popup>)
   :
   ''
      }
      </span>
);
}
}
export default Schedule;