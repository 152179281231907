import { 
  TASKS_FETCH_DATA,
  TASKS_FETCH_DATA_SUCCESS,
  TASKS_FETCH_DATA_ERROR,
  TASKS_FETCH_DATA_STATUS_SUCCESS,
  TASKS_FETCH_DATA_STATUS_ERROR,
  TASKS_FILE_UPLOAD,
  TASKS_DETAILS,
  TASKS_DETAILS_SUCCESS,
  TASKS_DETAILS_ERROR,
} from './constants';

const initialState = {
  tasksDataError: '',
  tasksData: '',
  tasksDataStatusError: '',
  tasksDataStatus: '',
  tasksDetailsError: '',
  tasksDetails: '',
};

export function tasksStudentReducer(state = initialState, action) {
  switch (action.type) {
	case  TASKS_FETCH_DATA:
      return {
        ...state,
     };
	case  TASKS_FETCH_DATA_SUCCESS:
      return {
        ...state,
        tasksData: action.data
     };
	case  TASKS_FETCH_DATA_ERROR:
      return {
        ...state,
        tasksDataError: 'Some error occured, please contact our customer spport'
     };
  case  TASKS_FETCH_DATA_STATUS_SUCCESS:
      return {
        ...state,
        tasksDataStatus: action.data
     };
	case  TASKS_FETCH_DATA_STATUS_ERROR:
      return {
        ...state,
        tasksDataStatusError: 'Some error occured, please contact our customer spport'
     };
  case TASKS_FILE_UPLOAD:
    return {
      ...state,
    }
    case  TASKS_DETAILS:
      return {
        ...state,
     };
	case  TASKS_DETAILS_SUCCESS:
    console.log('ss',action.data);
      return {
        ...state,
        tasksDetails: action.data
     };
	case  TASKS_DETAILS_ERROR:
      return {
        ...state,
        tasksDetailsError: 'Some error occured, please contact our customer spport'
     };
    default:
      return state;
  }
}
