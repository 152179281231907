import React, { Component } from 'react';
import { Link } from "react-router-dom";
import photoshop from '../../assets/images/photoshop.png';
import { FileUploadModal } from '../FileUploadModal';
import NoData from '../NoData';

const details = (selectedtaskId,tasksDetails) => {
    const payload={
        taskId: selectedtaskId,
    };
    return tasksDetails(payload);
}


const TaskItems=(props) => {
    const curriculumTitle = props.tasks ? props.tasks.curriculumTitle : '';
    const taskId = props.tasks ? props.tasks.taskId : '';
    const lessonName = props.tasks ? props.tasks.lessonName : '';
    const taskTitle = props.tasks ? props.tasks.taskTitle : '';
    const classIcon = props.tasks ? props.tasks.classIcon : '';
    const submissionDueDate = props.tasks ? props.tasks.submissionDueDate : '';
    const submissionStatus = props.tasks ? props.tasks.submissionStatus : '';
    const submittedDate = props.tasks ? props.tasks.submittedDate : '';
    const submitDate = (submissionStatus==='Submitted') ? submittedDate : submissionDueDate;
    const timeFormat = new Date(submitDate);
    return(
            <div className="col-md-6 col-lg-6 col-xl-3">
                <div className="card card-theme mb-4">
                    <div className="card-header flex-column">
                        <div className="course d-flex align-items-center">
                            <img src={photoshop} alt="photoshop" className="img-fluid mr-2 rounded-circle"/>
                        </div>
                    </div>
                    <div className="card-body">
                        <h4>{taskTitle.substr(0,23)}</h4>
                        <div className="lesson-inner">
                            <div className="lesson-heading">{lessonName}</div>
                        </div>
                        <div className="row icns">
                            <div className="col-md-12 col-lg-12">
                                <h4>{timeFormat.toLocaleString('en-US',{dateStyle: 'medium', timeStyle: 'short'})}</h4>
                                <div className="lesson-inner">
                                    {(submissionStatus!=='Submitted') ?
                                    (<div className="lesson-heading">Submission Due Date</div>)
                                    :
                                    (<div className="lesson-heading">Submitted Date</div>)
    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-foot">
                        <Link to="/TaskDetails" onClick={()=>details(taskId,props.tasksDetails)} style={{ cursor: 'default', color: 'inherit', textDecoration: 'inherit'}}><button type="button" className="btn btn-theme-light btn-rounded"><i className="fa fa-eye"></i> Details</button></Link>
                        {(submissionStatus!=='Submitted') ?
                            (<FileUploadModal tasks={props.tasks} tasksFileUpload={props.tasksFileUpload} origin={0} tasksDataUpload={props.tasksDataUpload}/>)
                            :
                            ''
                        }
                    </div>
                </div>
                
            </div>
    );
}


class Tasks extends Component {
    constructor(props) {
      super(props);
      this.setActiveTabCompleted = this.setActiveTabCompleted.bind(this);
      this.setActiveTabInProgress = this.setActiveTabInProgress.bind(this);
      this.state = {
        classUpcoming : 'nav-link active',
        classCompleted : 'nav-link',
        tabStaus: 1,
      }
    }
    componentDidMount() {
        this.props.tasksFetchData();
    }
    setActiveTabCompleted(e) {
        e.preventDefault();
        this.setState({
            classUpcoming: 'nav-link',
            classCompleted: 'nav-link active',
            tabStaus: 2,
        }   
        );
      }
    setActiveTabInProgress(e) {
        e.preventDefault();
        this.setState({
            classUpcoming: 'nav-link active',
            classCompleted: 'nav-link',
            tabStaus: 1
        });   
      }
    render() {
        var tasksArray = [];
        var progressCount = 0;
        const classStatus = this.state.tabStaus;
        if( classStatus===1){
            tasksArray = this.props.tasksStatusData ? this.props.tasksStatusData.progress : [];
            progressCount = tasksArray.length;
        } else {
            tasksArray = this.props.tasksStatusData ? this.props.tasksStatusData.completed : [];
        }
        
    return(
        <div className="dash-content flex-grow-1 mytaskspge">
           <div class="row">
                       <div class="col-md-8 col-7">
                           <h2 class="cus-heading mb-3">My Tasks</h2>
                       </div>
                       
                   </div>
            <section className="sliders mb-sm-4">
                <div className="row">
                    <div className="col-lg-12">
                           <ul className="nav nav-tabs">
                              <li className="nav-item">
                                <a className={this.state.classUpcoming} data-toggle="tab" href="#upcoming" onClick={this.setActiveTabInProgress}>In Progress &nbsp;&nbsp;<span className="badge badge-pill badge-warning">{progressCount}</span></a>
                              </li>
                              <li className="nav-item">
                                <a className={this.state.classCompleted} data-toggle="tab" href="#notscheduled" onClick={this.setActiveTabCompleted}>Completed</a>
                              </li>
                           </ul>
                           <div className="tab-content">
                                <div className="tab-pane  active mytasks" id="upcoming">
                                    <div className="card card-theme mb-4 nmecard">
                                        <div className="card-body">
                                            <div className="cuslessons">
                                                <div className="row">
                                                    {
                                                        tasksArray.length > 0 ?
                                                        (tasksArray.map((tasksData, index) => (
                                                                <TaskItems index={index} tasks={tasksData} key={index} tasksFileUpload={this.props.tasksFileUpload} tasksDetails={this.props.tasksDetails}/>
                                                                
                                                            
                                                        )))
                                                        :
                                                        <NoData apiCalled={this.props.tasksStatusData}/>
                                                        
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </section>
        </div>
);
}
}
export default Tasks;