import {
  STUDENT_FETCH_DATA,
  STUDENT_FETCH_DATA_SUCCESS,
  STUDENT_FETCH_DATA_ERROR,
  STUDENT_SAVE_DATA,
  STUDENT_SAVE_DATA_SUCCESS,
  STUDENT_SAVE_DATA_ERROR,
  UPLOAD_PROFILE_PHOTO,
} from './constants';

export function studentFetchData(data) {
  return {
    type:  STUDENT_FETCH_DATA,
    data,
  };
}
export function studentFetchDataSuccess(data) {
  return {
    type:  STUDENT_FETCH_DATA_SUCCESS,
	data,
  };
}
export function studentFetchDataError(error) {
  return {
    type:  STUDENT_FETCH_DATA_ERROR,
	  error,
  };
}
export function studentSaveData(data) {
  return {
    type:  STUDENT_SAVE_DATA,
    data,
  };
}
export function studentSaveDataSuccess(data) {
  return {
    type:  STUDENT_SAVE_DATA_SUCCESS,
	data,
  };
}
export function studentSaveDataError(error) {
  return {
    type:  STUDENT_SAVE_DATA_ERROR,
	  error,
  };
}
export function uploadPhoto(data) {
  return {
    type: UPLOAD_PROFILE_PHOTO,
    data,
  };
}