import React, { Component } from 'react';
import { Link } from "react-router-dom";
import psdmg from '../../assets/images/detailed_page_no_image.PNG';
import { FileUploadModal } from '../FileUploadModal';

class TaskDetails extends Component {
    constructor(props) {
      super(props);
      this.state = {
        classUpcoming : 'nav-link active',
        classNotScheduled : 'nav-link',
        classCompleted : 'nav-link',
        tabStaus: 1,
      }
    }
    componentDidMount(){
        
    }
  

render() {
    var displayTime = '';
    var submissionDate = '';
    var submissionText = '';
    const lessonId = this.props.taskDetailsData ? this.props.taskDetailsData.lessonId : '';
    const lessonTitle = this.props.taskDetailsData ? this.props.taskDetailsData.lessonTitle : '';
    const taskTitle = this.props.taskDetailsData ? this.props.taskDetailsData.taskTitle : '';
    const submissionDueDate = this.props.taskDetailsData ? this.props.taskDetailsData.submissionDueDate : '';
    const actualSubmittedDate = this.props.taskDetailsData ? this.props.taskDetailsData.actualSubmittedDate : '';
    const submissionStatus = this.props.taskDetailsData ? this.props.taskDetailsData.submissionStatus : '';
    const taskdescription = this.props.taskDetailsData ? this.props.taskDetailsData.taskDesc : '';
    if(submissionStatus===1){
      submissionDate=actualSubmittedDate;
      submissionText='Submitted Date';
    }else{
      submissionDate=submissionDueDate;
      submissionText='Submission Due Date';
    }
    if(submissionDate){
      const timeFormat = new Date(submissionDate);
      const defaultTime = new Date();
      if(defaultTime!==timeFormat) {
      displayTime=timeFormat.toLocaleString('en-US',{dateStyle: 'medium', timeStyle: 'short'});
      }
    }
    return(
        <div>
            <div class="bimger">
                   <div class="container-fluid">
                      <div class="row">
                       <div class="col-md-4 col-xl-2 col-12">
                           <h2 class="allessn"> <Link to="/Tasks" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><i class="fa fa-arrow-left" aria-hidden="true"></i> All Tasks </Link></h2> 
                           <img src={psdmg} alt="My order" class="img-fluid"/>
                        </div>
                        <div class="col-md-6 col-xl-6 lsns">
                            <h2 class="allessn"><span class="lsnspan">Lesson {lessonId}</span> {lessonTitle}</h2> 
                            <h1>{taskTitle}</h1> 
                           <div class="row lcnschdl">
                             <div class="col-md-6 col-lg-6 col-6">
                              <h2 class="allessn"><i class="far fa-clock"></i>{submissionText}: {displayTime}</h2> 
                             </div>
                          </div>
                       </div>
                       <div class="col-md-2 col-12 cnlnbtn">
                           <FileUploadModal tasks={this.props.taskDetailsData} tasksFileUpload={this.props.tasksFileUpload} origin={0} tasksDataUpload={this.props.tasksDataUpload}/>
                       </div>
                       </div>
                    </div>
                </div>
                <div class="header-bg">
                   <div class="container">
                       <div class="row schdlsn">
                           <div class="col-md-12">
                               <div class="row">
                                   <div class="col-md-7">
                                       <h2>Assignment Topic </h2>
                                   </div>
  
                               </div>
                                
                                <div dangerouslySetInnerHTML={{__html: taskdescription}}/>  
                               
                           </div>
                       </div>
                   </div> 
                </div>
               <div class="dash-content flex-grow-1">
                   
               </div>
        </div>
);
}
}
export default TaskDetails;