import React, { useRef } from 'react';
import { Link } from "react-router-dom";
import photoshop from '../../assets/images/noimage.png';
import Schedule from '../../containers/Schedule';
import CancelModal from '../CancelModal';
import NoData from '../NoData';

 // TODO img classIcon needs to be modified

 const zoomClass = (zoomLink) => {
     if(zoomLink){
         return (window.open(zoomLink,'_blank','fullscreen=1'));
     }else{
         return '';
     }
 }

const details = (selectedLessonId,lessonDetails) => {
    const payload={
        lessonId: selectedLessonId,
    };
    return lessonDetails(payload);
}



const LessonItems=(index, lessonsCancel) => {
    var displayTime = '';
    var buttonDisabled = false;
    const lessonId = index.lessons ? index.lessons.lessonId : '';
    const lessonName = index.lessons ? index.lessons.lessonName : '';
    const lessonTitle = index.lessons ? index.lessons.title : '';
    const lessonTeacher = index.lessons ? index.lessons.teacherName : '';
    const classIcon = index.lessons ? index.lessons.classIcon : '';
    const startTime = index.lessons ? index.lessons.startTime : '';
    const zoomLink = index.lessons ? index.lessons.zoomLink : '';
    var scheduleText = startTime ? 'Cancel' : 'Schedule';
    const description = index.lessons ? index.lessons.description : '';
    const timeFormat = new Date(startTime);
    const defaultTime = new Date();
    var buttonClass = 'btn btn-theme-light btn-rounded wticnmb';
    if(startTime) {
        displayTime=timeFormat.toLocaleString('en-US',{dateStyle: 'medium', timeStyle: 'short'});
    }
    var displayTimeDisable = timeFormat.getTime();
    var todaydate = new Date().getTime();
    var timeGap = (displayTimeDisable-todaydate)/(1000*3600);
    if(zoomLink && (timeGap <= 24) && (timeGap >= 0)){
        buttonDisabled = true;
        scheduleText = 'Go To Class';
        buttonClass = 'btn btn-theme-light btn-rounded actvbtn wticnmb';
    }
    return(
        <div className="col-md-6 col-lg-6 col-xl-4 col-sm-6 col-12">
            <div className="card card-theme mb-4">
                <div className="card-header flex-column">
                    <div className="course d-flex align-items-center">
                        <img src={classIcon || photoshop } alt="photoshop" className="img-fluid mr-2 rounded-circle" />
                        <p className="mb-0">{lessonTitle}</p>
                    </div>
                    <h4>{lessonName.substr(0,30)}</h4>
                </div>
                <div className="card-body">
                    <div className="lesson-inner">
                        <div className="lesson-heading">{description.substr(0,100)}... </div>
                    </div>
                    <div className="row icns">
                        <div className="col-md-6 col-lg-6  col-6 brdr">
                            <p><i className="fa fa-graduation-cap" aria-hidden="true"></i></p>
                            <p>{lessonTeacher}</p>
                        </div>
                        <div className="col-md-6 col-lg-6 col-6">
                            <p><i className="fa fa-calendar-alt"></i></p>
                            <p>{displayTime}</p>
                        </div>
                    </div>
                </div>
                <div className="card-foot">
                <Link to="/LessonDetails" onClick={()=>details(lessonId,index.lessonDetails)} style={{ cursor: 'default', color: 'inherit', textDecoration: 'inherit'}}><button type="button" class="btn btn-theme-light btn-rounded wticnmb" ><i class="fa fa-eye"></i> Details</button></Link>
                    {
                    (scheduleText==='Schedule') ?
                        <Schedule lessons={index.lessons} refreshIndex={1}/>
                     :( (scheduleText==='Cancel') ?
                        <CancelModal lessons={index.lessons} lessonsCancel={index.lessonsCancel} refreshIndex={1}/>
                     :
                     <button 
                     class={buttonClass} disabled={buttonDisabled} onClick={()=>zoomClass(zoomLink)}><i class="fas fa-calendar-alt"></i> {scheduleText}</button>
                     )
                    }
                </div>
            </div>
            
        </div>
    );
}

const HomeLessons=(props) =>{
    var lessonArray = props.lessonData ? props.lessonData : [];
    var lessonsCount = 0;
    if(Array.isArray(lessonArray)) {
        lessonsCount = lessonArray.length;
        const sliceLength = (lessonArray.length > 2) ? 3 : lessonsCount;
        lessonArray = lessonArray.slice(0, sliceLength);
    }
    return(
        <div className="card card-theme mb-4">
            <div className="card-header">
                <h4>Upcoming Lessons</h4>
                <Link to="/Lessons" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className="viewall">View All <span className="badge badge-theme">{lessonsCount}</span></div></Link>
            </div>
            <div className="card-body">
                <div className="cuslessons">
                    <div className="row">
                        {
                        lessonArray.length > 0 ?
                            (lessonArray.map((lessonData, index) => (
                            <LessonItems index={index} lessons={lessonData} key={index} lessonsCancel={props.lessonsCancel} lessonDetails={props.lessonDetails}/>
                            )))
                            :
                            <NoData apiCalled={props.lessonData} />
                        }
                    </div>
                </div>
            </div>
        </div>
);
}
export default HomeLessons;