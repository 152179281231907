import { createSelector } from 'reselect';

const rateTeacherReducer = (state) => state.rateTeacherReducer;

export const makeSelectRateTeacherError = () => createSelector(
  rateTeacherReducer,
  (rateTeacher) => {
	return rateTeacher.rateTeacherDataError;
  }
);
export const makeSelectRateTeacherData = () => createSelector(
  rateTeacherReducer,
  (rateTeacher) => {
	return rateTeacher.rateTeacherData;
  }
);
