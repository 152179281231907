import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { STUDENT_FETCH_DATA, API_END_POINT } from '../Student/constants';
import { makeSelectStudentId } from '../Student/selectors';
import { TASKS_FETCH_DATA, TASKS_FILE_UPLOAD, TASKS_FILE_UPLOAD_SUCCESS, TASKS_DETAILS,TASKS_DATA_UPLOAD_SUCCESS, TASKS_DATA_UPLOAD } from './constants';
import { 
  tasksFetchDataSuccess,
  tasksFetchDataError,
  tasksFetchDataStatusSuccess,
  tasksFetchDataStatusError,
  tasksFileUploadSuccess,
  tasksFileUploadError,
  tasksDataUploadSuccess,
  tasksDataUploadError,
  tasksDetailsSuccess,
  tasksDetailsError,
 } from './actions';
import request, { headers } from '../../utils/request';


export function* fetchTasksData(payload) {
  const studentId = yield select(makeSelectStudentId());
  const DEFAULT_TIMEOUT = 20000;
  const requestURL = `${API_END_POINT}/student/${studentId}/task/pending`;
  try{
    const response = yield call(request, requestURL, { timeout: DEFAULT_TIMEOUT, headers });
	  yield put(tasksFetchDataSuccess(response));
  } catch (error) {
    yield put(tasksFetchDataError(error));
  }
  
}

export function* fetchTasksDataStatus(payload) {
  const studentId = yield select(makeSelectStudentId());
  const DEFAULT_TIMEOUT = 20000;
  const requestURLPending = `${API_END_POINT}/student/${studentId}/task/pending`;
  const requestURLCompleted = `${API_END_POINT}/student/${studentId}/task/completed`;
  try{
    const responseProgress = yield call(request, requestURLPending, { timeout: DEFAULT_TIMEOUT, headers });
    const responseCompleted = yield call(request, requestURLCompleted, { timeout: DEFAULT_TIMEOUT, headers });
    const response = {
      progress: responseProgress,
      completed: responseCompleted
    }
	  yield put(tasksFetchDataStatusSuccess(response));
  } catch (error) {
    yield put(tasksFetchDataStatusError(error));
  }
  
}

export function* fileUpload(payload) {
  const studentId = yield select(makeSelectStudentId());
  const headers = { 'Content-Type': 'multipart/form-data' };
  const data = payload.data.formData || '';
  var taskData = payload.data.taskData || '';
  const DEFAULT_TIMEOUT = 20000;
  const requestURL = `${API_END_POINT}/student/${studentId}/task/upload`;
  try{
    const response = yield call(request, requestURL, { method: 'POST', data, timeout: DEFAULT_TIMEOUT, headers });
    taskData['attachmentLink'] = response ? response.success.message : '';
    const taskFileData = { response, taskData };
	  yield put(tasksFileUploadSuccess(taskFileData));
  } catch (error) {
    yield put(tasksFileUploadError(error));
  }
}
export function* dataUpload(payload) {
  const studentId = yield select(makeSelectStudentId());
  const data = payload.data.taskData || '';
  const requestURL = `${API_END_POINT}/student/${studentId}/task`;
  const DEFAULT_TIMEOUT = 20000;
  const res = {};
  try{
    const response = yield call(request, requestURL, { method: 'POST', data, timeout: DEFAULT_TIMEOUT, headers });
    yield put(tasksDataUploadSuccess(res));
  } catch (error) {
    yield put(tasksDataUploadError(error));
  }
}

export function* taskDetails(payload) {
  const studentId = yield select(makeSelectStudentId());
  const taskId = payload.data ? payload.data.taskId: '';
  const DEFAULT_TIMEOUT = 20000;
  const requestURL = `${API_END_POINT}/student/${studentId}/task/${taskId}`;
  try{
    const response = yield call(request, requestURL, { timeout: DEFAULT_TIMEOUT, headers });
	  yield put(tasksDetailsSuccess(response));
  } catch (error) {
    yield put(tasksDetailsError(error));
  }
  
}

export function* fetchTasksDataDaemon() {
  yield takeLatest([STUDENT_FETCH_DATA,TASKS_DATA_UPLOAD_SUCCESS], fetchTasksData);
}
export function* fetchTasksStatusDataDaemon() {
  yield takeLatest([TASKS_FETCH_DATA,TASKS_DATA_UPLOAD_SUCCESS], fetchTasksDataStatus);
}
export function* fileUploadDaemon() {
  yield takeLatest(TASKS_FILE_UPLOAD, fileUpload);
}
export function* dataUploadDaemon() {
  yield takeLatest([TASKS_FILE_UPLOAD_SUCCESS,TASKS_DATA_UPLOAD], dataUpload);
}
export function* taskDetailsDaemon() {
  yield takeLatest(TASKS_DETAILS, taskDetails);
}

export default function* taskSaga() {
  yield all([
    fetchTasksDataDaemon(),
    fetchTasksStatusDataDaemon(),
    fileUploadDaemon(),
    dataUploadDaemon(),
    taskDetailsDaemon(),
  ]);
}