import React, { Component } from 'react';
import photoshop from '../../assets/images/noimage.png';
import Popup from '../Popup';
import './styles.scss';

const uploadF=()=> {
   const uploadTag = document.getElementById("inputUpload");
   uploadTag.click();
}

export class FileUploadModal extends Component{
   constructor(props) {
       super(props);
       this.onChange = this.onChange.bind(this);
       this.openModal = this.openModal.bind(this);
       this.closeModal = this.closeModal.bind(this);
       this.handleSubmit = this.handleSubmit.bind(this);
       this.state ={
           file:null,
           showModal:false,
         };
   }
   openModal=()=>{
      this.setState({showModal:true});
    }
   onChange(e) {
       this.setState({file:e.target.files[0]});
   }
   closeModal(boolSubmit){
      if(boolSubmit){
         this.handleSubmit();
      }
      this.setState({showModal:false});
    }
   async handleSubmit() {
      //e.preventDefault();
      const comments = document.getElementById('exampleFormControlTextarea1').value;
      const assignedTaskId = this.props.tasks.assignedTaskId || '';
      const formData = new FormData();
      const taskData = { assignedTaskId, comments }
      formData.append('avatar',this.state.file)
      var dataUpload ={ formData, taskData };
      if(!this.state.file){
         dataUpload.taskData['attachmentLink']='';
         this.props.tasksDataUpload(dataUpload);
      }else{
         this.props.tasksFileUpload(dataUpload);
      }
   }  

render(){
   const curriculumTitle = this.props.tasks ? this.props.tasks.curriculumTitle : '';
   const lessonName = this.props.tasks ? this.props.tasks.lessonName : '';
   const taskTitle = this.props.tasks ? this.props.tasks.taskTitle : '';
   const classIcon = this.props.tasks ? this.props.tasks.classIcon : '';
   const submissionDueDate = this.props.tasks ? this.props.tasks.submissionDueDate : '';
   const timeFormat = new Date(submissionDueDate);
   const displayFormat = timeFormat.toLocaleString('en-US',{dateStyle: 'short', timeStyle: 'short'});
   const taskDescr = this.props.tasks ? this.props.tasks.taskDesc : '';
   const origin = this.props.origin ? this.props.origin : 0;
    return(
      <span>
      { (origin===1) ?
         <a href={void(0)} className="btn btn-theme-light btn-rounded" onClick={()=>this.openModal()} data-toggle="modal" data-target="#submitmd"><i className="fa fa-upload"></i></a>
         :
         <button className="btn btn-theme-light btn-rounded" onClick={()=>this.openModal()} data-toggle="modal" data-target="#submitmd" ><i className="fa fa-arrow-up" aria-hidden="true"></i>Submit</button>
         }
       
      { this.state.showModal ?
      (<Popup>
        <div className="modal fade bgmdl" id="submitmd" tabindex="-1" role="dialog" aria-labelledby="submitmd" aria-hidden="true">
        <div className="modal-dialog" role="document">
           <div className="modal-content">
              <div className="modal-header">
                 <h5 className="modal-title" id="exampleModalLabel">Submit Assignment</h5>
                 <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.closeModal(false)}>
                 <span aria-hidden="true">&times;</span>
                 </button>
              </div>
              <div className="modal-body">
                  <div className="course d-flex align-items-center">
                    <img src={photoshop} alt="photoshop" className="img-fluid mr-2 rounded-circle"/>
                    <p className="mb-0">{curriculumTitle}</p>
                  </div>
                  <p className="asnmnt">{lessonName}|{taskTitle}</p>
                  <p className="txtasn">{taskDescr}</p>
              </div>
              <div className="modal-footer">
                 <span>Upload your Assignment work ﬁle:<a href={void(0)} onClick={()=>uploadF()}><i className="fa fa-arrow-up" aria-hidden="true"></i>Upload File</a></span>
                 <input className="inputUploadStyle" id="inputUpload" type="file" onChange={ this.onChange } />
                  {this.state.file ?
                     <div className="uploadesfiles">
                        <p><b>{this.state.file.name}<i className="far fa-trash-alt"></i></b></p>
                     </div>
                  :
                     <div className="uploadesfiles">
                        <p><b>No File Selected</b></p>
                     </div>
                  }
                 <div className="form-group">
                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Comments"></textarea>
                 </div>
                 <button className="btn btn-theme-light btn-rounded sbmitfoot" data-dismiss="modal" onClick={() => this.closeModal(true)}>Submit</button>
              </div>
           </div>
        </div>
     </div>
     </Popup>)
     :
      ''
   }
   </span>
    );
}
}