import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Schedule from '../../components/Shedule';
import { lessonsSchedule,lessonsScheduleUpdate } from '../Lessons/actions';
import { makeSelectStudentLessonSchedule } from '../Lessons/selectors'

const mapStateToProps = createStructuredSelector({
  scheduleDetails: makeSelectStudentLessonSchedule(),
});

export function mapDispatchToProps(dispatch) {
  return {
    lessonsSchedule: (data) => dispatch(lessonsSchedule(data)),
    scheduleUpdate: (data) => dispatch(lessonsScheduleUpdate(data)),
    dispatch,
  };
}

const LessonSchedule = connect(mapStateToProps,mapDispatchToProps)(Schedule);

export default LessonSchedule;