import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Layout from '../../components/Layout';
import { studentFetchData,getSudentId } from  '../Student/actions';
import { lessonsDetails, lessonsCancel } from '../Lessons/actions';
import { tasksDetails, tasksFileUpload, tasksDataUpload } from  '../Tasks/actions';
import {
  makeSelectCoursesError,
  makeSelectCoursesData,
  makeSelectAdvData,
 } from '../Courses/selectors';
import { makeSelectStudentSearchError,makeSelectStudentSearchData, makeSelectStudentId } from '../Student/selectors';
import { makeSelectStudentLessonsError, makeSelectStudentLessonsData } from '../Lessons/selectors'
import { makeSelectStudentTaskData } from '../Tasks/selectors';
import { makeSelectRateTeacherData } from '../RateTeacher/selectors';
import { makeSelectMessagesData } from '../Messages/selectors';

const mapStateToProps = createStructuredSelector({
  studentDataError: makeSelectStudentSearchError(),
  studentData: makeSelectStudentSearchData(),
  coursesDataError: makeSelectCoursesError(),
  coursesData: makeSelectCoursesData(),
  advData: makeSelectAdvData(),
  lessonData: makeSelectStudentLessonsData(),
  lessanDataError: makeSelectStudentLessonsError(),
  tasksData: makeSelectStudentTaskData(),
  rateTeacherData: makeSelectRateTeacherData(),
  messagesData: makeSelectMessagesData(),
  studentId: makeSelectStudentId(),
 });

export function mapDispatchToProps(dispatch) {
  return {
    studentFetchData: (studentData) => dispatch(studentFetchData(studentData)),
    lessonsCancel: (data) => dispatch(lessonsCancel(data)),
    lessonDetails: (data) => dispatch(lessonsDetails(data)),
    tasksFileUpload: (data) => dispatch(tasksFileUpload(data)),
    tasksDetails: (data) => dispatch(tasksDetails(data)),
    tasksDataUpload: (data) => dispatch(tasksDataUpload(data)),
    dispatch,
  };
}

const LayoutContainer = connect(mapStateToProps,mapDispatchToProps)(Layout);

export default LayoutContainer;